import { useRouter } from 'next/router'
import Image from 'next/image'

import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { useTranslation } from 'next-i18next'

import Container from 'components/Container'
import InfoBox, { InfoBoxProps } from 'components/InfoBox'
import Modal from 'components/Modal'
import LanguageMenu from 'components/LanguageDropdown'
import { LogoImage } from 'components/SectionHeader'

const LoginPage = ({ message, type }: InfoBoxProps) => {
  const router = useRouter()
  const { t } = useTranslation(['login', 'common'])

  return (
    <div className="bg-black h-full min-h-screen">
      <main className="pb-24">
        <section className="py-6">
          <Container className="flex justify-between">
            <LogoImage />
            <LanguageMenu />
          </Container>
        </section>

        <section className="w-full">
          <Container className="bg-white py-16 px-5 rounded-lg md:px-10">
            <h1 className="font-serif text-[2.75rem] text-center mb-16 leading-10">
              {t('login-title')}
            </h1>

            <div>
              {message && (
                <div className="py-6">
                  <InfoBox message={t(message)} type={type} />
                </div>
              )}

              <Formik
                initialValues={{
                  reserva: ''
                }}
                validationSchema={Yup.object({
                  reserva: Yup.string().required(t('errors.login-required'))
                })}
                onSubmit={async (values) => {
                  router.push({
                    pathname: '/',
                    query: { reserva: values.reserva.replace(/\s/g, '') }
                  })
                }}
              >
                {({ errors }) => (
                  <Form>
                    <p
                      className="text-neutral-900 pb-2 login-label"
                      dangerouslySetInnerHTML={{
                        __html: t('login-description')
                      }}
                    />
                    <Field
                      id="reserva"
                      name="reserva"
                      placeholder={t('login-placeholder')}
                      className={`form-input ${
                        !!errors.reserva && 'form-input--error'
                      }`}
                    />

                    <ErrorMessage
                      name="reserva"
                      component="span"
                      className="error-message"
                    />

                    <button type="submit" className="mt-8 button--dark w-full">
                      {t('button.search')}
                    </button>

                    <div className="pt-24 text-center">
                      <Modal trigger={<a>{t('help.question')}</a>}>
                        <div className="flex flex-col gap-4">
                          <Image
                            src={`/img/illustrations/helpdesk.svg`}
                            width={150}
                            height={150}
                            objectFit="contain"
                            quality={100}
                          />

                          <div>
                            <h1 className="font-medium">
                              {t('help.question')}
                            </h1>
                            <p className="mb-4">{t('help.answer')}</p>
                          </div>

                          <a
                            className="button--dark text-white font-medium rounded-3xl  hover:text-white focus:outline-neutral-300 no-underline visited:text-white decoration-white"
                            href="https://wa.me/5511940758022"
                            target="_blank"
                            role="button"
                            rel="noreferrer"
                          >
                            <Image
                              width="24"
                              height="24"
                              src="/img/icons/whatsapp.svg"
                              className="invert"
                            />
                            {t('help.whatsapp')}
                          </a>
                        </div>
                      </Modal>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Container>
        </section>
      </main>
    </div>
  )
}

export default LoginPage
