import Image from 'next/image'

import Container from 'components/Container'
import LanguageMenu from 'components/LanguageDropdown'

type SectionHeaderProps = {
  title: string
  subtitle?: string
  color?: 'dark' | 'light'
  isLarge?: boolean
  isSerif?: boolean
  hideTopMenu?: boolean
}

export const LogoImage = ({ color }: Pick<SectionHeaderProps, 'color'>) => (
  <Image
    width="100"
    height="22"
    src="/img/logo.svg"
    className={`${color === 'light' && 'invert'}`}
  />
)

const SectionHeader = ({
  title,
  subtitle,
  color = 'light',
  isLarge = false,
  isSerif = false,
  hideTopMenu = false
}: SectionHeaderProps) => {
  const sectionStyles = `${isLarge ? 'pt-6 pb-24' : 'py-6'}`
  const colorModifiers = {
    title: {
      light: 'text-black',
      dark: 'text-white'
    },
    subtitle: {
      light: 'text-neutral-600',
      dark: 'text-neutral-400'
    },
    background: {
      light: 'bg-white',
      dark: 'bg-black'
    }
  }

  return (
    <section className={`${sectionStyles} ${colorModifiers.background[color]}`}>
      {!hideTopMenu && (
        <Container className="pb-6 flex justify-between">
          <LogoImage color={color} />
          <LanguageMenu />
        </Container>
      )}

      <Container>
        <h1
          className={`text-2xl ${colorModifiers.title[color]} ${
            isSerif && 'font-serif text-2xl'
          }`}
        >
          {title}
        </h1>
        {!!subtitle && (
          <p className={`text-sm ${colorModifiers.subtitle[color]}`}>
            {subtitle}
          </p>
        )}
      </Container>
    </section>
  )
}

export default SectionHeader
