import Image from 'next/image'
import { useRouter } from 'next/router'

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

const LanguageMenu = () => {
  const itemStyles =
    'flex gap-2 items-center w-full py-2 px-4 cursor-pointer bg-white text-xs hover:outline-none hover:bg-black hover:text-white'

  const router = useRouter()
  const { locale, pathname, asPath, query } = router

  let activeLocale = ''

  switch (locale) {
    case 'pt':
      activeLocale = 'Português (BR)'
      break
    case 'en':
      activeLocale = 'English (US)'
      break
    default:
      activeLocale = 'Português (BR)'
  }

  const handleClick = (nextLocale: string) => () => {
    if (nextLocale === locale) return

    router.push({ pathname, query }, asPath, { locale: nextLocale })
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="flex items-center justify-center gap-2 p-2 min-w-[128px] rounded-lg bg-white hover:bg-slate-100 text-xs ring-1 ring-neutral-900/5 shadow-md">
        <Image
          src={`/img/flags/${locale}.png`}
          width={16}
          height={16}
          objectFit="cover"
          className="rounded-full"
          quality={80}
        />
        <span>{activeLocale}</span>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="p-1 shadow-md rounded bg-slate-100">
        <DropdownMenu.Item>
          <button className={itemStyles} onClick={handleClick('pt')}>
            <Image
              src="/img/flags/pt.png"
              width={16}
              height={16}
              objectFit="cover"
              className="rounded-full"
              quality={80}
            />
            <span>Português (BR)</span>
          </button>
        </DropdownMenu.Item>
        <DropdownMenu.Item>
          <button className={itemStyles} onClick={handleClick('en')}>
            <Image
              src="/img/flags/en.png"
              width={16}
              height={16}
              objectFit="cover"
              className="rounded-full"
              quality={80}
            />
            <span>English (US)</span>
          </button>
        </DropdownMenu.Item>

        <DropdownMenu.Arrow className="text-slate-100" fill="currentColor" />
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}

export default LanguageMenu
