type ContainerProps = {
  children: React.ReactNode
  className?: string
}

const Container = ({ children, className }: ContainerProps) => {
  const containerClasses = `container mx-auto w-11/12 max-w-2xl ${
    className ? className : ''
  }`

  return <div className={containerClasses}>{children}</div>
}

export default Container
