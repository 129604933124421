export type InfoBoxProps = {
  message: string
  type?: 'error' | 'warning' | 'info' | 'success'
}

const InfoBox = ({ message, type = 'warning' }: InfoBoxProps) => {
  const colorModifiers = {
    error: 'bg-rose-100 text-rose-800',
    warning: 'bg-yellow-100 text-yellow-800',
    info: 'bg-indigo-100 text-indigo-800',
    success: 'bg-emerald-100 text-emerald-800'
  }

  return (
    <div className={`p-3 rounded-sm ${colorModifiers[type]}`}>
      <p className="text-sm text-center">{message}</p>
    </div>
  )
}

export default InfoBox
